import { tourBookingConstants } from '../constants';
import { tourBookingService } from '../services';
import { alertActions } from '../actions/alert.actions'
import { history } from '../helpers';


export const tourBookingActions = {
    putProgrammeSlot,
    bookProgramme,
    getBookedProgramme,
    updateSlotSelection,
    storeBookingId,
    clearBookingId,
    confirmSlotSelection
};

function putProgrammeSlot(data) {
    return dispatch => {
        dispatch(request({ data }));
        tourBookingService.putProgrammeSlot(data).then(
            data => {
                dispatch(success(data));
            },
            error => {
                dispatch(failure(error.Message));
            }
        );
    };

    function request(data) {
        return { type: tourBookingConstants.PUT_PROGRAMME_SLOT_REQUEST, data };
    }

    function success(data) {
        return { type: tourBookingConstants.PUT_PROGRAMME_SLOT_SUCCESS, data };
    }

    function failure(data) {
        return { type: tourBookingConstants.PUT_PROGRAMME_SLOT_FAILURE, data };
    }
}

function bookProgramme(data, BookingId = "", slotData, key, InActiveBookingExpiryInMins) {
    return dispatch => {
        dispatch(request({ data }));
        tourBookingService.bookProgramme(data, BookingId).then(
            result => {
                dispatch(success(result));
                if (data.length > 0) {
                    dispatch(storeBookingId(result.BookingId))
                }
                else if (data.length === 0 && key !== 'goToCalendar') {
                    dispatch(clearBookingId())
                } else if (data.length === 0 && key === 'goToCalendar') {
                    let dataObj = {
                        key: 'cartExpired',
                        InActiveBookingExpiryInMins: InActiveBookingExpiryInMins
                    }
                    history.push({ pathname: 'ProgrammeList', state: dataObj })
                    window.location.reload(true);
                }
                if (slotData) {
                    dispatch(putProgrammeSlot(slotData))
                }
            },
            error => {
                dispatch(failure(error.Message));
                dispatch(alertActions.error(error.Message ? error.Message : 'Something went wrong', true));
            }
        );
    };

    function request(data) {
        return { type: tourBookingConstants.BOOK_TOUR_REQUEST, data };
    }

    function success(data) {
        return { type: tourBookingConstants.BOOK_TOUR_SUCCESS, data };
    }

    function failure(data) {
        return { type: tourBookingConstants.BOOK_TOUR_FAILURE, data };
    }
}

function updateSlotSelection(data) {
    return dispatch => {
        dispatch(request({ data }));
        tourBookingService.updateSlotSelection(data).then(
            data => {
                dispatch(success(data));
            },
            error => {
                dispatch(failure(error.Message));
                dispatch(alertActions.error(error.Message ? error.Message : 'Something went wrong', true, error.Status === 'OverBooked' ? 'reload' : undefined));
            }
        );
    };

    function request(data) {
        return { type: tourBookingConstants.BOOK_TOUR_REQUEST, data };
    }

    function success(data) {
        return { type: tourBookingConstants.BOOK_TOUR_SUCCESS, data };
    }

    function failure(data) {
        return { type: tourBookingConstants.BOOK_TOUR_FAILURE, data };
    }
}

function getBookedProgramme(data, bookingform) {
    return dispatch => {
        dispatch(request({ data }));
        tourBookingService.getBookedProgramme(data).then(
            data => {
                dispatch(success(data));
                dispatch(storeBookingId(data.BookingId))
            },
            error => {
                dispatch(failure(error.Message));
                if (bookingform === 'bookingform') {
                    history.push('/mybookings')
                    window.location.reload(true);
                }
                // dispatch(alertActions.error(error.Message?error.Message:'Something went wrong', true));
            }
        );
    };

    function request(data) {
        return { type: tourBookingConstants.GET_BOOKED_TOUR_REQUEST, data };
    }

    function success(data) {
        return { type: tourBookingConstants.GET_BOOKED_TOUR_SUCCESS, data };
    }

    function failure(data) {
        return { type: tourBookingConstants.GET_BOOKED_TOUR_FAILURE, data };
    }
}

function storeBookingId(data) {
    return { type: tourBookingConstants.STROED_BOOKED_ID, data };
}

function clearBookingId(data) {
    return { type: tourBookingConstants.CLEAR_BOOKED_ID, data };
}

function confirmSlotSelection(data) {
    return dispatch => {
        dispatch(request({ data }));
        tourBookingService.confirmSlotSelection(data).then(
            data => {
                dispatch(success(data));

            },
            error => {
                dispatch(failure(error.Message));
                dispatch(alertActions.error(error.Message ? error.Message : 'Something went wrong', true));
            }
        );
    };

    function request(data) {
        return { type: tourBookingConstants.GET_CONFIRM_SLOT_REQUEST, data };
    }

    function success(data) {
        return { type: tourBookingConstants.GET_CONFIRM_SLOT_SUCCESS, data };
    }

    function failure(data) {
        return { type: tourBookingConstants.GET_CONFIRM_SLOT_FAILURE, data };
    }
}
